<script>
import { BIconFacebook, BIconTwitter } from 'bootstrap-vue'

export default {
  name: 'ShareModal',
  props: {
    modalId: String,
  },
  components: {
    BIconFacebook,
    BIconTwitter,
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    hideModal(showModalId = null) {
      this.$refs['share'].hide()
      showModalId && this.$bvModal.show(showModalId)
    },
  },
  computed: {
    currentPath() {
      return window.location.pathname
    },
  },
}
</script>

<style scoped lang="scss"></style>
